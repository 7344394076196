@use "sass:math";
@charset "utf-8";
@import "./constants.scss";
@import "~bulma-checkradio";

$white: white;
$black: black;

$mint                   : #1fc2a7;
$mint-invert: findColorInvert($mint);

$mint-darker-10         : #1caf96;
$mint-darker-20         : #199b86;
$mint-darker-30         : #168875;
$mint-darker-40         : #137464;
$mint-darker-50         : #106154;
$mint-darker-60         : #0c4e43;
$mint-darker-70         : #093a32;
$mint-darker-80         : #062721;
$mint-darker-90         : #031311;

$mint-lighter-10        : #35c8b0;
$mint-lighter-20        : #4cceb9;
$mint-lighter-30        : #62d4c1;
$mint-lighter-40        : #79daca;
$mint-lighter-50        : #8fe1d3;
$mint-lighter-60        : #a5e7dc;
$mint-lighter-70        : #bcede5;
$mint-lighter-80        : #d2f3ed;
$mint-lighter-90        : #e9f9f6;

$lightblue              : #4FB3D9;

$lightblue-darker-10    : #47a1c3;
$lightblue-darker-20    : #3f8fae;
$lightblue-darker-30    : #377d98;
$lightblue-darker-40    : #2f6b82;
$lightblue-darker-50    : #285a6d;
$lightblue-darker-60    : #204857;
$lightblue-darker-70    : #183641;
$lightblue-darker-80    : #10242b;
$lightblue-darker-80    : #081216;

$lightblue-lighter-10   : #61bbdd;
$lightblue-lighter-20   : #72c2e1;
$lightblue-lighter-30   : #84cae4;
$lightblue-lighter-40   : #95d1e8;
$lightblue-lighter-50   : #a7d9ec;
$lightblue-lighter-60   : #b9e1f0;
$lightblue-lighter-70   : #cae8f4;
$lightblue-lighter-80   : #dcf0f7;
$lightblue-lighter-90   : #edf7fb;

$darkblue               : #2D3E50;

$darkblue-darker-10     : #293848;
$darkblue-darker-20     : #243240;
$darkblue-darker-30     : #1f2b38;
$darkblue-darker-40     : #1b2530;
$darkblue-darker-50     : #171f28;
$darkblue-darker-60     : #121920;
$darkblue-darker-70     : #0d1318;
$darkblue-darker-80     : #090c10;
$darkblue-darker-90     : #040608;

$darkblue-lighter-10    : #425162;
$darkblue-lighter-20    : #576573;
$darkblue-lighter-30    : #6c7885;
$darkblue-lighter-40    : #818b96;
$darkblue-lighter-50    : #969fa8;
$darkblue-lighter-60    : #abb2b9;
$darkblue-lighter-70    : #c0c5cb;
$darkblue-lighter-80    : #d5d8dc;
$darkblue-lighter-90    : #eaecee;

$yellow                 : #F9CD69;

$yellow-darker-10 : #e6ad00;
$yellow-darker-20       : #cc9a00;
$yellow-darker-30       : #b38600;
$yellow-darker-40       : #997300;
$yellow-darker-50       : #806000;
$yellow-darker-60       : #664d00;
$yellow-darker-70       : #4c3a00;
$yellow-darker-80       : #332600;
$yellow-darker-90       : #191300;

$yellow-lighter-10      : #ffc61a;
$yellow-lighter-20      : #ffcd33;
$yellow-lighter-30      : #ffd34d;
$yellow-lighter-40      : #ffd966;
$yellow-lighter-50      : #ffe080;
$yellow-lighter-60      : #ffe699;
$yellow-lighter-70      : #ffecb3;
$yellow-lighter-80      : #fff2cc;
$yellow-lighter-90      : #fff9e6;

$olive                  : #b4ce82;

$olive-darker-10        : #a2b975;
$olive-darker-20        : #90a568;
$olive-darker-30        : #7e905b;
$olive-darker-40        : #6c7c4e;
$olive-darker-50        : #5a6741;
$olive-darker-60        : #485234;
$olive-darker-70        : #363e27;
$olive-darker-80        : #24291a;
$olive-darker-90        : #12150d;

$olive-lighter-10       : #bcd38f;
$olive-lighter-20       : #c3d89b;
$olive-lighter-30       : #cbdda8;
$olive-lighter-40       : #d2e2b4;
$olive-lighter-50       : #dae7c1;
$olive-lighter-60       : #e1ebcd;
$olive-lighter-70       : #e9f0da;
$olive-lighter-80       : #f0f5e6;
$olive-lighter-90       : #f8faf3;

$red                    : #c21f3a;

$red-darker-10          : #AE1B34;
$red-darker-20          : #9B182E;
$red-darker-30          : #871528;
$red-darker-40          : #741222;
$red-darker-50          : #600F1D;
$red-darker-60          : #4D0C17;
$red-darker-70          : #3A0911;
$red-darker-80          : #26060B;
$red-darker-90          : #130305;

$red-lighter-10         : #C8354D;
$red-lighter-20         : #CE4B61;
$red-lighter-30         : #D46275;
$red-lighter-40         : #DA7888;
$red-lighter-50         : #E08F9C;
$red-lighter-60         : #E6A5B0;
$red-lighter-70         : #ECBBC3;
$red-lighter-80         : #F2D2D7;
$red-lighter-90         : #F8E8EB;

// taken from https://medium.com/dev-channel/using-sass-to-automatically-pick-text-colors-4ba7645d2796
@function luminance($color) {
    $red: nth($linear-channel-values, red($color) + 1);
    $green: nth($linear-channel-values, green($color) + 1);
    $blue: nth($linear-channel-values, blue($color) + 1);
  
    @return .2126 * $red + .7152 * $green + .0722 * $blue;
}

/**
* Calculate the contrast ratio between two colors.
* See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
*/
@function contrast($back, $front) {
    $backLum: luminance($back) + .05;
    $foreLum: luminance($front) + .05;

    @return math.div(max($backLum, $foreLum) , min($backLum, $foreLum));
}
  
/**
* Determine whether to use dark or light text on top of given color.
* Returns black for dark text and white for light text.
*/
@function choose-contrast-color($color) {
    $lightContrast: contrast($color, white);
    $darkContrast: contrast($color, black);

    @if ($lightContrast > $darkContrast) {
        @return white;
    }
    @else {
        @return black;
    }
}

@mixin is($color){
    color:$color !important
}

@mixin has-bg($color){
    background-color: $color !important;
    color: choose-contrast-color($color) !important;
}

@mixin has-border-color($color){
    border: 3px solid;
    border-color: $color !important;
}

@mixin has-bg-split($color,$percentage){
    background-color: $color;
    color: choose-contrast-color($color);
    @media screen and (min-width: 1024px) {
        background: linear-gradient(rgba(0,0,0,0) percentage($percentage), $color percentage($percentage));
    }
}

/**
* sets the elements color
*/
.is-white                  {@include is($white)}
.is-black                  {@include is($black)}

.is-mint                   {@include is($mint                   )}

.is-mint-darker-10         {@include is($mint-darker-10         )}
.is-mint-darker-20         {@include is($mint-darker-20         )}
.is-mint-darker-30         {@include is($mint-darker-30         )}
.is-mint-darker-40         {@include is($mint-darker-40         )}
.is-mint-darker-50         {@include is($mint-darker-50         )}
.is-mint-darker-60         {@include is($mint-darker-60         )}
.is-mint-darker-70         {@include is($mint-darker-70         )}
.is-mint-darker-80         {@include is($mint-darker-80         )}
.is-mint-darker-90         {@include is($mint-darker-90         )}

.is-mint-lighter-10        {@include is($mint-lighter-10        )}
.is-mint-lighter-20        {@include is($mint-lighter-20        )}
.is-mint-lighter-30        {@include is($mint-lighter-30        )}
.is-mint-lighter-40        {@include is($mint-lighter-40        )}
.is-mint-lighter-50        {@include is($mint-lighter-50        )}
.is-mint-lighter-60        {@include is($mint-lighter-60        )}
.is-mint-lighter-70        {@include is($mint-lighter-70        )}
.is-mint-lighter-80        {@include is($mint-lighter-80        )}
.is-mint-lighter-90        {@include is($mint-lighter-90        )}

.is-lightblue              {@include is($lightblue              )}

.is-lightblue-darker-10    {@include is($lightblue-darker-10    )}
.is-lightblue-darker-20    {@include is($lightblue-darker-20    )}
.is-lightblue-darker-30    {@include is($lightblue-darker-30    )}
.is-lightblue-darker-40    {@include is($lightblue-darker-40    )}
.is-lightblue-darker-50    {@include is($lightblue-darker-50    )}
.is-lightblue-darker-60    {@include is($lightblue-darker-60    )}
.is-lightblue-darker-70    {@include is($lightblue-darker-70    )}
.is-lightblue-darker-80    {@include is($lightblue-darker-80    )}
.is-lightblue-darker-80    {@include is($lightblue-darker-80    )}

.is-lightblue-lighter-10   {@include is($lightblue-lighter-10   )}
.is-lightblue-lighter-20   {@include is($lightblue-lighter-20   )}
.is-lightblue-lighter-30   {@include is($lightblue-lighter-30   )}
.is-lightblue-lighter-40   {@include is($lightblue-lighter-40   )}
.is-lightblue-lighter-50   {@include is($lightblue-lighter-50   )}
.is-lightblue-lighter-60   {@include is($lightblue-lighter-60   )}
.is-lightblue-lighter-70   {@include is($lightblue-lighter-70   )}
.is-lightblue-lighter-80   {@include is($lightblue-lighter-80   )}
.is-lightblue-lighter-90   {@include is($lightblue-lighter-90   )}

.is-darkblue               {@include is($darkblue               )}

.is-darkblue-darker-10     {@include is($darkblue-darker-10     )}
.is-darkblue-darker-20     {@include is($darkblue-darker-20     )}
.is-darkblue-darker-30     {@include is($darkblue-darker-30     )}
.is-darkblue-darker-40     {@include is($darkblue-darker-40     )}
.is-darkblue-darker-50     {@include is($darkblue-darker-50     )}
.is-darkblue-darker-60     {@include is($darkblue-darker-60     )}
.is-darkblue-darker-70     {@include is($darkblue-darker-70     )}
.is-darkblue-darker-80     {@include is($darkblue-darker-80     )}
.is-darkblue-darker-90     {@include is($darkblue-darker-90     )}

.is-darkblue-lighter-10    {@include is($darkblue-lighter-10    )}
.is-darkblue-lighter-20    {@include is($darkblue-lighter-20    )}
.is-darkblue-lighter-30    {@include is($darkblue-lighter-30    )}
.is-darkblue-lighter-40    {@include is($darkblue-lighter-40    )}
.is-darkblue-lighter-50    {@include is($darkblue-lighter-50    )}
.is-darkblue-lighter-60    {@include is($darkblue-lighter-60    )}
.is-darkblue-lighter-70    {@include is($darkblue-lighter-70    )}
.is-darkblue-lighter-80    {@include is($darkblue-lighter-80    )}
.is-darkblue-lighter-90    {@include is($darkblue-lighter-90    )}

.is-yellow                 {@include is($yellow                 )}

.is-yellow-darker-10       {@include is($yellow-darker-10       )}
.is-yellow-darker-20       {@include is($yellow-darker-20       )}
.is-yellow-darker-30       {@include is($yellow-darker-30       )}
.is-yellow-darker-40       {@include is($yellow-darker-40       )}
.is-yellow-darker-50       {@include is($yellow-darker-50       )}
.is-yellow-darker-60       {@include is($yellow-darker-60       )}
.is-yellow-darker-70       {@include is($yellow-darker-70       )}
.is-yellow-darker-80       {@include is($yellow-darker-80       )}
.is-yellow-darker-90       {@include is($yellow-darker-90       )}

.is-yellow-lighter-10      {@include is($yellow-lighter-10      )}
.is-yellow-lighter-20      {@include is($yellow-lighter-20      )}
.is-yellow-lighter-30      {@include is($yellow-lighter-30      )}
.is-yellow-lighter-40      {@include is($yellow-lighter-40      )}
.is-yellow-lighter-50      {@include is($yellow-lighter-50      )}
.is-yellow-lighter-60      {@include is($yellow-lighter-60      )}
.is-yellow-lighter-70      {@include is($yellow-lighter-70      )}
.is-yellow-lighter-80      {@include is($yellow-lighter-80      )}
.is-yellow-lighter-90      {@include is($yellow-lighter-90      )}

.is-olive                  {@include is($olive                  )}

.is-olive-darker-10        {@include is($olive-darker-10        )}
.is-olive-darker-20        {@include is($olive-darker-20        )}
.is-olive-darker-30        {@include is($olive-darker-30        )}
.is-olive-darker-40        {@include is($olive-darker-40        )}
.is-olive-darker-50        {@include is($olive-darker-50        )}
.is-olive-darker-60        {@include is($olive-darker-60        )}
.is-olive-darker-70        {@include is($olive-darker-70        )}
.is-olive-darker-80        {@include is($olive-darker-80        )}
.is-olive-darker-90        {@include is($olive-darker-90        )}

.is-olive-lighter-10       {@include is($olive-lighter-10       )}
.is-olive-lighter-20       {@include is($olive-lighter-20       )}
.is-olive-lighter-30       {@include is($olive-lighter-30       )}
.is-olive-lighter-40       {@include is($olive-lighter-40       )}
.is-olive-lighter-50       {@include is($olive-lighter-50       )}
.is-olive-lighter-60       {@include is($olive-lighter-60       )}
.is-olive-lighter-70       {@include is($olive-lighter-70       )}
.is-olive-lighter-80       {@include is($olive-lighter-80       )}
.is-olive-lighter-90       {@include is($olive-lighter-90       )}

.is-red                    {@include is($red                    )}

.is-red-darker-10          {@include is($red-darker-10          )}
.is-red-darker-20          {@include is($red-darker-20          )}
.is-red-darker-30          {@include is($red-darker-30          )}
.is-red-darker-40          {@include is($red-darker-40          )}
.is-red-darker-50          {@include is($red-darker-50          )}
.is-red-darker-60          {@include is($red-darker-60          )}
.is-red-darker-70          {@include is($red-darker-70          )}
.is-red-darker-80          {@include is($red-darker-80          )}
.is-red-darker-90          {@include is($red-darker-90          )}
 
.is-red-lighter-10         {@include is($red-lighter-10         )}
.is-red-lighter-20         {@include is($red-lighter-20         )}
.is-red-lighter-30         {@include is($red-lighter-30         )}
.is-red-lighter-40         {@include is($red-lighter-40         )}
.is-red-lighter-50         {@include is($red-lighter-50         )}
.is-red-lighter-60         {@include is($red-lighter-60         )}
.is-red-lighter-70         {@include is($red-lighter-70         )}
.is-red-lighter-80         {@include is($red-lighter-80         )}
.is-red-lighter-90         {@include is($red-lighter-90         )}
 
/**
* sets the background color with the correct contrast color for text
*/

.has-bg-white                  {@include has-bg($white)}
.has-bg-black                  {@include has-bg($black)}

.has-bg-mint                   {@include has-bg($mint                   )}

.has-bg-mint-darker-10         {@include has-bg($mint-darker-10         )}
.has-bg-mint-darker-20         {@include has-bg($mint-darker-20         )}
.has-bg-mint-darker-30         {@include has-bg($mint-darker-30         )}
.has-bg-mint-darker-40         {@include has-bg($mint-darker-40         )}
.has-bg-mint-darker-50         {@include has-bg($mint-darker-50         )}
.has-bg-mint-darker-60         {@include has-bg($mint-darker-60         )}
.has-bg-mint-darker-70         {@include has-bg($mint-darker-70         )}
.has-bg-mint-darker-80         {@include has-bg($mint-darker-80         )}
.has-bg-mint-darker-90         {@include has-bg($mint-darker-90         )}

.has-bg-mint-lighter-10        {@include has-bg($mint-lighter-10        )}
.has-bg-mint-lighter-20        {@include has-bg($mint-lighter-20        )}
.has-bg-mint-lighter-30        {@include has-bg($mint-lighter-30        )}
.has-bg-mint-lighter-40        {@include has-bg($mint-lighter-40        )}
.has-bg-mint-lighter-50        {@include has-bg($mint-lighter-50        )}
.has-bg-mint-lighter-60        {@include has-bg($mint-lighter-60        )}
.has-bg-mint-lighter-70        {@include has-bg($mint-lighter-70        )}
.has-bg-mint-lighter-80        {@include has-bg($mint-lighter-80        )}
.has-bg-mint-lighter-90        {@include has-bg($mint-lighter-90        )}

.has-bg-lightblue              {@include has-bg($lightblue              )}

.has-bg-lightblue-darker-10    {@include has-bg($lightblue-darker-10    )}
.has-bg-lightblue-darker-20    {@include has-bg($lightblue-darker-20    )}
.has-bg-lightblue-darker-30    {@include has-bg($lightblue-darker-30    )}
.has-bg-lightblue-darker-40    {@include has-bg($lightblue-darker-40    )}
.has-bg-lightblue-darker-50    {@include has-bg($lightblue-darker-50    )}
.has-bg-lightblue-darker-60    {@include has-bg($lightblue-darker-60    )}
.has-bg-lightblue-darker-70    {@include has-bg($lightblue-darker-70    )}
.has-bg-lightblue-darker-80    {@include has-bg($lightblue-darker-80    )}
.has-bg-lightblue-darker-80    {@include has-bg($lightblue-darker-80    )}

.has-bg-lightblue-lighter-10   {@include has-bg($lightblue-lighter-10   )}
.has-bg-lightblue-lighter-20   {@include has-bg($lightblue-lighter-20   )}
.has-bg-lightblue-lighter-30   {@include has-bg($lightblue-lighter-30   )}
.has-bg-lightblue-lighter-40   {@include has-bg($lightblue-lighter-40   )}
.has-bg-lightblue-lighter-50   {@include has-bg($lightblue-lighter-50   )}
.has-bg-lightblue-lighter-60   {@include has-bg($lightblue-lighter-60   )}
.has-bg-lightblue-lighter-70   {@include has-bg($lightblue-lighter-70   )}
.has-bg-lightblue-lighter-80   {@include has-bg($lightblue-lighter-80   )}
.has-bg-lightblue-lighter-90   {@include has-bg($lightblue-lighter-90   )}

.has-bg-darkblue               {@include has-bg($darkblue               )}

.has-bg-darkblue-darker-10     {@include has-bg($darkblue-darker-10     )}
.has-bg-darkblue-darker-20     {@include has-bg($darkblue-darker-20     )}
.has-bg-darkblue-darker-30     {@include has-bg($darkblue-darker-30     )}
.has-bg-darkblue-darker-40     {@include has-bg($darkblue-darker-40     )}
.has-bg-darkblue-darker-50     {@include has-bg($darkblue-darker-50     )}
.has-bg-darkblue-darker-60     {@include has-bg($darkblue-darker-60     )}
.has-bg-darkblue-darker-70     {@include has-bg($darkblue-darker-70     )}
.has-bg-darkblue-darker-80     {@include has-bg($darkblue-darker-80     )}
.has-bg-darkblue-darker-90     {@include has-bg($darkblue-darker-90     )}

.has-bg-darkblue-lighter-10    {@include has-bg($darkblue-lighter-10    )}
.has-bg-darkblue-lighter-20    {@include has-bg($darkblue-lighter-20    )}
.has-bg-darkblue-lighter-30    {@include has-bg($darkblue-lighter-30    )}
.has-bg-darkblue-lighter-40    {@include has-bg($darkblue-lighter-40    )}
.has-bg-darkblue-lighter-50    {@include has-bg($darkblue-lighter-50    )}
.has-bg-darkblue-lighter-60    {@include has-bg($darkblue-lighter-60    )}
.has-bg-darkblue-lighter-70    {@include has-bg($darkblue-lighter-70    )}
.has-bg-darkblue-lighter-80    {@include has-bg($darkblue-lighter-80    )}
.has-bg-darkblue-lighter-90    {@include has-bg($darkblue-lighter-90    )}

.has-bg-yellow                 {@include has-bg($yellow                 )}

.has-bg-yellow-darker-10       {@include has-bg($yellow-darker-10       )}
.has-bg-yellow-darker-20       {@include has-bg($yellow-darker-20       )}
.has-bg-yellow-darker-30       {@include has-bg($yellow-darker-30       )}
.has-bg-yellow-darker-40       {@include has-bg($yellow-darker-40       )}
.has-bg-yellow-darker-50       {@include has-bg($yellow-darker-50       )}
.has-bg-yellow-darker-60       {@include has-bg($yellow-darker-60       )}
.has-bg-yellow-darker-70       {@include has-bg($yellow-darker-70       )}
.has-bg-yellow-darker-80       {@include has-bg($yellow-darker-80       )}
.has-bg-yellow-darker-90       {@include has-bg($yellow-darker-90       )}

.has-bg-yellow-lighter-10      {@include has-bg($yellow-lighter-10      )}
.has-bg-yellow-lighter-20      {@include has-bg($yellow-lighter-20      )}
.has-bg-yellow-lighter-30      {@include has-bg($yellow-lighter-30      )}
.has-bg-yellow-lighter-40      {@include has-bg($yellow-lighter-40      )}
.has-bg-yellow-lighter-50      {@include has-bg($yellow-lighter-50      )}
.has-bg-yellow-lighter-60      {@include has-bg($yellow-lighter-60      )}
.has-bg-yellow-lighter-70      {@include has-bg($yellow-lighter-70      )}
.has-bg-yellow-lighter-80      {@include has-bg($yellow-lighter-80      )}
.has-bg-yellow-lighter-90      {@include has-bg($yellow-lighter-90      )}

.has-bg-olive                  {@include has-bg($olive                  )}

.has-bg-olive-darker-10        {@include has-bg($olive-darker-10        )}
.has-bg-olive-darker-20        {@include has-bg($olive-darker-20        )}
.has-bg-olive-darker-30        {@include has-bg($olive-darker-30        )}
.has-bg-olive-darker-40        {@include has-bg($olive-darker-40        )}
.has-bg-olive-darker-50        {@include has-bg($olive-darker-50        )}
.has-bg-olive-darker-60        {@include has-bg($olive-darker-60        )}
.has-bg-olive-darker-70        {@include has-bg($olive-darker-70        )}
.has-bg-olive-darker-80        {@include has-bg($olive-darker-80        )}
.has-bg-olive-darker-90        {@include has-bg($olive-darker-90        )}

.has-bg-olive-lighter-10       {@include has-bg($olive-lighter-10       )}
.has-bg-olive-lighter-20       {@include has-bg($olive-lighter-20       )}
.has-bg-olive-lighter-30       {@include has-bg($olive-lighter-30       )}
.has-bg-olive-lighter-40       {@include has-bg($olive-lighter-40       )}
.has-bg-olive-lighter-50       {@include has-bg($olive-lighter-50       )}
.has-bg-olive-lighter-60       {@include has-bg($olive-lighter-60       )}
.has-bg-olive-lighter-70       {@include has-bg($olive-lighter-70       )}
.has-bg-olive-lighter-80       {@include has-bg($olive-lighter-80       )}
.has-bg-olive-lighter-90       {@include has-bg($olive-lighter-90       )}

.has-bg-red                    {@include has-bg($red                    )}

.has-bg-red-darker-10          {@include has-bg($red-darker-10          )}
.has-bg-red-darker-20          {@include has-bg($red-darker-20          )}
.has-bg-red-darker-30          {@include has-bg($red-darker-30          )}
.has-bg-red-darker-40          {@include has-bg($red-darker-40          )}
.has-bg-red-darker-50          {@include has-bg($red-darker-50          )}
.has-bg-red-darker-60          {@include has-bg($red-darker-60          )}
.has-bg-red-darker-70          {@include has-bg($red-darker-70          )}
.has-bg-red-darker-80          {@include has-bg($red-darker-80          )}
.has-bg-red-darker-90          {@include has-bg($red-darker-90          )}

.has-bg-red-lighter-10         {@include has-bg($red-lighter-10         )}
.has-bg-red-lighter-20         {@include has-bg($red-lighter-20         )}
.has-bg-red-lighter-30         {@include has-bg($red-lighter-30         )}
.has-bg-red-lighter-40         {@include has-bg($red-lighter-40         )}
.has-bg-red-lighter-50         {@include has-bg($red-lighter-50         )}
.has-bg-red-lighter-60         {@include has-bg($red-lighter-60         )}
.has-bg-red-lighter-70         {@include has-bg($red-lighter-70         )}
.has-bg-red-lighter-80         {@include has-bg($red-lighter-80         )}
.has-bg-red-lighter-90         {@include has-bg($red-lighter-90         )}


/**
* sets the border color
*/

.has-border-color-white                  {@include has-border-color($white)}
.has-border-color-black                  {@include has-border-color($black)}

.has-border-color-mint                   {@include has-border-color($mint                   )}

.has-border-color-mint-darker-10         {@include has-border-color($mint-darker-10         )}
.has-border-color-mint-darker-20         {@include has-border-color($mint-darker-20         )}
.has-border-color-mint-darker-30         {@include has-border-color($mint-darker-30         )}
.has-border-color-mint-darker-40         {@include has-border-color($mint-darker-40         )}
.has-border-color-mint-darker-50         {@include has-border-color($mint-darker-50         )}
.has-border-color-mint-darker-60         {@include has-border-color($mint-darker-60         )}
.has-border-color-mint-darker-70         {@include has-border-color($mint-darker-70         )}
.has-border-color-mint-darker-80         {@include has-border-color($mint-darker-80         )}
.has-border-color-mint-darker-90         {@include has-border-color($mint-darker-90         )}

.has-border-color-mint-lighter-10        {@include has-border-color($mint-lighter-10        )}
.has-border-color-mint-lighter-20        {@include has-border-color($mint-lighter-20        )}
.has-border-color-mint-lighter-30        {@include has-border-color($mint-lighter-30        )}
.has-border-color-mint-lighter-40        {@include has-border-color($mint-lighter-40        )}
.has-border-color-mint-lighter-50        {@include has-border-color($mint-lighter-50        )}
.has-border-color-mint-lighter-60        {@include has-border-color($mint-lighter-60        )}
.has-border-color-mint-lighter-70        {@include has-border-color($mint-lighter-70        )}
.has-border-color-mint-lighter-80        {@include has-border-color($mint-lighter-80        )}
.has-border-color-mint-lighter-90        {@include has-border-color($mint-lighter-90        )}

.has-border-color-lightblue              {@include has-border-color($lightblue              )}

.has-border-color-lightblue-darker-10    {@include has-border-color($lightblue-darker-10    )}
.has-border-color-lightblue-darker-20    {@include has-border-color($lightblue-darker-20    )}
.has-border-color-lightblue-darker-30    {@include has-border-color($lightblue-darker-30    )}
.has-border-color-lightblue-darker-40    {@include has-border-color($lightblue-darker-40    )}
.has-border-color-lightblue-darker-50    {@include has-border-color($lightblue-darker-50    )}
.has-border-color-lightblue-darker-60    {@include has-border-color($lightblue-darker-60    )}
.has-border-color-lightblue-darker-70    {@include has-border-color($lightblue-darker-70    )}
.has-border-color-lightblue-darker-80    {@include has-border-color($lightblue-darker-80    )}
.has-border-color-lightblue-darker-80    {@include has-border-color($lightblue-darker-80    )}

.has-border-color-lightblue-lighter-10   {@include has-border-color($lightblue-lighter-10   )}
.has-border-color-lightblue-lighter-20   {@include has-border-color($lightblue-lighter-20   )}
.has-border-color-lightblue-lighter-30   {@include has-border-color($lightblue-lighter-30   )}
.has-border-color-lightblue-lighter-40   {@include has-border-color($lightblue-lighter-40   )}
.has-border-color-lightblue-lighter-50   {@include has-border-color($lightblue-lighter-50   )}
.has-border-color-lightblue-lighter-60   {@include has-border-color($lightblue-lighter-60   )}
.has-border-color-lightblue-lighter-70   {@include has-border-color($lightblue-lighter-70   )}
.has-border-color-lightblue-lighter-80   {@include has-border-color($lightblue-lighter-80   )}
.has-border-color-lightblue-lighter-90   {@include has-border-color($lightblue-lighter-90   )}

.has-border-color-darkblue               {@include has-border-color($darkblue               )}

.has-border-color-darkblue-darker-10     {@include has-border-color($darkblue-darker-10     )}
.has-border-color-darkblue-darker-20     {@include has-border-color($darkblue-darker-20     )}
.has-border-color-darkblue-darker-30     {@include has-border-color($darkblue-darker-30     )}
.has-border-color-darkblue-darker-40     {@include has-border-color($darkblue-darker-40     )}
.has-border-color-darkblue-darker-50     {@include has-border-color($darkblue-darker-50     )}
.has-border-color-darkblue-darker-60     {@include has-border-color($darkblue-darker-60     )}
.has-border-color-darkblue-darker-70     {@include has-border-color($darkblue-darker-70     )}
.has-border-color-darkblue-darker-80     {@include has-border-color($darkblue-darker-80     )}
.has-border-color-darkblue-darker-90     {@include has-border-color($darkblue-darker-90     )}

.has-border-color-darkblue-lighter-10    {@include has-border-color($darkblue-lighter-10    )}
.has-border-color-darkblue-lighter-20    {@include has-border-color($darkblue-lighter-20    )}
.has-border-color-darkblue-lighter-30    {@include has-border-color($darkblue-lighter-30    )}
.has-border-color-darkblue-lighter-40    {@include has-border-color($darkblue-lighter-40    )}
.has-border-color-darkblue-lighter-50    {@include has-border-color($darkblue-lighter-50    )}
.has-border-color-darkblue-lighter-60    {@include has-border-color($darkblue-lighter-60    )}
.has-border-color-darkblue-lighter-70    {@include has-border-color($darkblue-lighter-70    )}
.has-border-color-darkblue-lighter-80    {@include has-border-color($darkblue-lighter-80    )}
.has-border-color-darkblue-lighter-90    {@include has-border-color($darkblue-lighter-90    )}

.has-border-color-yellow                 {@include has-border-color($yellow                 )}

.has-border-color-yellow-darker-10       {@include has-border-color($yellow-darker-10       )}
.has-border-color-yellow-darker-20       {@include has-border-color($yellow-darker-20       )}
.has-border-color-yellow-darker-30       {@include has-border-color($yellow-darker-30       )}
.has-border-color-yellow-darker-40       {@include has-border-color($yellow-darker-40       )}
.has-border-color-yellow-darker-50       {@include has-border-color($yellow-darker-50       )}
.has-border-color-yellow-darker-60       {@include has-border-color($yellow-darker-60       )}
.has-border-color-yellow-darker-70       {@include has-border-color($yellow-darker-70       )}
.has-border-color-yellow-darker-80       {@include has-border-color($yellow-darker-80       )}
.has-border-color-yellow-darker-90       {@include has-border-color($yellow-darker-90       )}

.has-border-color-yellow-lighter-10      {@include has-border-color($yellow-lighter-10      )}
.has-border-color-yellow-lighter-20      {@include has-border-color($yellow-lighter-20      )}
.has-border-color-yellow-lighter-30      {@include has-border-color($yellow-lighter-30      )}
.has-border-color-yellow-lighter-40      {@include has-border-color($yellow-lighter-40      )}
.has-border-color-yellow-lighter-50      {@include has-border-color($yellow-lighter-50      )}
.has-border-color-yellow-lighter-60      {@include has-border-color($yellow-lighter-60      )}
.has-border-color-yellow-lighter-70      {@include has-border-color($yellow-lighter-70      )}
.has-border-color-yellow-lighter-80      {@include has-border-color($yellow-lighter-80      )}
.has-border-color-yellow-lighter-90      {@include has-border-color($yellow-lighter-90      )}

.has-border-color-olive                  {@include has-border-color($olive                  )}

.has-border-color-olive-darker-10        {@include has-border-color($olive-darker-10        )}
.has-border-color-olive-darker-20        {@include has-border-color($olive-darker-20        )}
.has-border-color-olive-darker-30        {@include has-border-color($olive-darker-30        )}
.has-border-color-olive-darker-40        {@include has-border-color($olive-darker-40        )}
.has-border-color-olive-darker-50        {@include has-border-color($olive-darker-50        )}
.has-border-color-olive-darker-60        {@include has-border-color($olive-darker-60        )}
.has-border-color-olive-darker-70        {@include has-border-color($olive-darker-70        )}
.has-border-color-olive-darker-80        {@include has-border-color($olive-darker-80        )}
.has-border-color-olive-darker-90        {@include has-border-color($olive-darker-90        )}

.has-border-color-olive-lighter-10       {@include has-border-color($olive-lighter-10       )}
.has-border-color-olive-lighter-20       {@include has-border-color($olive-lighter-20       )}
.has-border-color-olive-lighter-30       {@include has-border-color($olive-lighter-30       )}
.has-border-color-olive-lighter-40       {@include has-border-color($olive-lighter-40       )}
.has-border-color-olive-lighter-50       {@include has-border-color($olive-lighter-50       )}
.has-border-color-olive-lighter-60       {@include has-border-color($olive-lighter-60       )}
.has-border-color-olive-lighter-70       {@include has-border-color($olive-lighter-70       )}
.has-border-color-olive-lighter-80       {@include has-border-color($olive-lighter-80       )}
.has-border-color-olive-lighter-90       {@include has-border-color($olive-lighter-90       )}

.has-border-color-red                    {@include has-border-color($red                    )}

.has-border-color-red-darker-10          {@include has-border-color($red-darker-10          )}
.has-border-color-red-darker-20          {@include has-border-color($red-darker-20          )}
.has-border-color-red-darker-30          {@include has-border-color($red-darker-30          )}
.has-border-color-red-darker-40          {@include has-border-color($red-darker-40          )}
.has-border-color-red-darker-50          {@include has-border-color($red-darker-50          )}
.has-border-color-red-darker-60          {@include has-border-color($red-darker-60          )}
.has-border-color-red-darker-70          {@include has-border-color($red-darker-70          )}
.has-border-color-red-darker-80          {@include has-border-color($red-darker-80          )}
.has-border-color-red-darker-90          {@include has-border-color($red-darker-90          )}

.has-border-color-red-lighter-10         {@include has-border-color($red-lighter-10         )}
.has-border-color-red-lighter-20         {@include has-border-color($red-lighter-20         )}
.has-border-color-red-lighter-30         {@include has-border-color($red-lighter-30         )}
.has-border-color-red-lighter-40         {@include has-border-color($red-lighter-40         )}
.has-border-color-red-lighter-50         {@include has-border-color($red-lighter-50         )}
.has-border-color-red-lighter-60         {@include has-border-color($red-lighter-60         )}
.has-border-color-red-lighter-70         {@include has-border-color($red-lighter-70         )}
.has-border-color-red-lighter-80         {@include has-border-color($red-lighter-80         )}
.has-border-color-red-lighter-90         {@include has-border-color($red-lighter-90         )}


.has-bg-split-10-mint       {@include has-bg-split($mint, 0.1)}
.has-bg-split-20-mint       {@include has-bg-split($mint, 0.2)}
.has-bg-split-30-mint       {@include has-bg-split($mint, 0.3)}
.has-bg-split-10-lightblue  {@include has-bg-split($lightblue, 0.1)}
.has-bg-split-20-lightblue  {@include has-bg-split($lightblue, 0.2)}
.has-bg-split-30-lightblue  {@include has-bg-split($lightblue, 0.3)}
.has-bg-split-10-darkblue   {@include has-bg-split($darkblue, 0.1)}
.has-bg-split-20-darkblue   {@include has-bg-split($darkblue, 0.2)}
.has-bg-split-30-darkblue   {@include has-bg-split($darkblue, 0.3)}
.has-bg-split-10-olive      {@include has-bg-split($olive, 0.1)}
.has-bg-split-20-olive      {@include has-bg-split($olive, 0.2)}
.has-bg-split-30-olive      {@include has-bg-split($olive, 0.3)}

.has-rounded-border {
    border-radius: 6px;
}

.pt-10 {
    padding-top: 10% !important
}
.pb-10 {
    padding-bottom: 10% !important
}

.align-items-bottom {
    margin-top: auto;
}

.align-items-top {
    margin-bottom: auto;
}

// Bulma overwrites

$link: $lightblue;
$warning: $yellow;


$navbar-item-hover-background-color: $darkblue-lighter-20;
$navbar-item-color: white;
$navbar-item-hover-color: $white;
$navbar-item-active-color: $lightblue;
$navbar-item-active-background-color:$darkblue-lighter-20;


$custom-colors: (
    "nfdired": ($red),
    "nfdimint": ($mint),
    "nfdiolive": ($olive),
    "nfdidark": ($darkblue)
);

@import "../../node_modules/bulma/bulma.sass";


body {
    background-color: $white-bis;
}

.code-example {
    display: block;
    padding: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: $darkblue;
    color: $white !important;
}

.details-card p{
    font-size: 1.5em !important;
    margin-bottom: 0.5em!important;
}

.card-container {
    max-width: 70%;
    margin: 0 auto;
}

.dropdown-item .nested-dropdown {
    display: none;
    position: absolute;
    left: 100%;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0.5em 1em -0.125em rgb(0 0 0 / 10%), 0 0px 0 1px rgb(0 0 0 / 2%);
    /*padding: 0.5rem 0px;*/
    margin: 0px 1px;
    color: black
}

.responsive-dropdown-subcontent {
    display: none !important
}

@media only screen and (max-width: 600px) {
    .dropdown-item .nested-dropdown {
        display: none !important;
    }

    .responsive-dropdown-subcontent {
        display: inherit !important
    }

    .responsive-dropdown-content {
        display: none !important
    }
}

.dropdown-content .dropdown-item:nth-child(n+4) .nested-dropdown {
    bottom: 0;
}

.dropdown-content .dropdown-item:nth-child(-n+3) .nested-dropdown {
    top: 0;
}

/*sadly not useful for nice keaboard navigation*/
/*.dropdown-item:hover .nested-dropdown {
    display: block
}*/

.nested-dropdown div {
    font-size: 0.875rem;
    padding: 0.375rem 1rem
}

/*sadly not useful for nice keaboard navigation*/
/*.nested-dropdown div:hover {
    background-color: whitesmoke
}*/

@media only screen and (min-width: 400px) {

    // need this for maximum mobile support
    .min-hide-opposite {
        display: none !important
    }
}

@media only screen and (max-width: 400px) {
    // need this for maximum mobile support

    .decrease-padding {
        padding: 0.25rem
    }

    .decrease-margin-bottom {
        margin-bottom: 0.25rem !important
    }

    .min-flex-column {
        display: flex;
        flex-direction: column
    }

    .min-hide {
        display: none !important
    }

}